<template>
  <b-card
      no-body
      class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Estadísticas Solicitudes Ejecutante</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">

      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">

      <!-- loading state -->
      <template v-if="isLoading">
        <div class="text-center">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
      </template>

      <!-- data section -->
      <template v-else>
        <b-row>

          <!--        En Proceso-->
          <b-col
              xl="3"
              sm="6"
              class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    variant="light-info"
                >
                  <feather-icon
                      size="24"
                      icon="EditIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data['enProceso']  }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  En Proceso
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <!--        Pendientes-->

          <b-col
              xl="3"
              sm="6"
              class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    variant="primary"
                >
                  <feather-icon
                      size="24"
                      icon="ClockIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data['pendientes']  }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Pendientes
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>



          <!--        Rechazadas-->

          <b-col
              xl="3"
              sm="6"
              class="mb-2 mb-sm-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    variant="light-success"
                >
                  <feather-icon
                      size="24"
                      icon="CheckIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data['realizadas']  }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Realizadas
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>


          <!--        Canceladas-->

          <b-col
              xl="3"
              sm="6"
              class=""
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="DeleteIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data['canceladas']  }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Canceladas
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

        </b-row>
      </template>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BSpinner
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BSpinner
  },

  data(){

    return {

      isLoading: false,
      data: [],

    }
  },

  methods: {

    ...mapActions('dashboard-ejecutante', ['getEstadisticasEjecutante']),

    async loadData(){

      try {

        this.isLoading = true
        this.data = await  this.getEstadisticasEjecutante()

      } catch (error) {

      } finally {
        this.isLoading = false
      }


    }
  },
  async created() {
    await this.loadData()
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
