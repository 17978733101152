<template>
  <b-card
      no-body
      class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Solicitudes por estado</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">

      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">

      <!-- loading state -->
      <template v-if="isLoading">
        <div class="text-center">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
      </template>

      <!-- data section -->
      <template v-else>
        <b-row>

          <!--        borrador-->
          <b-col
              xl="3"
              sm="6"
              class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    variant="light-warning"
                >
                  <feather-icon
                      size="24"
                      icon="Edit2Icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data['borradorSolicitante']  }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Borrador
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <!--        creadas-->

          <b-col
              xl="3"
              sm="6"
              class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    variant="primary"
                >
                  <feather-icon
                      size="24"
                      icon="FolderPlusIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data['creadasSolicitante']  }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Creadas
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>



          <!--        Anuladas-->

          <b-col
              xl="3"
              sm="6"
              class="mb-2 mb-sm-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="XCircleIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data['anuladasSolicitante']  }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Anuladas
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>


          <!--        Enviadas-->

          <b-col
              xl="3"
              sm="6"
              class=""
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    variant="light-success"
                >
                  <feather-icon
                      size="24"
                      icon="SendIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data['enviadasSolicitante']  }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Enviadas
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

        </b-row>
      </template>






    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BSpinner
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import dashboardSolicitudesModule from '@/modules/solicitante/store/dashboard'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BSpinner
  },

  data(){

    return {

      isLoading: false,
      data: [],

    }
  },

  methods: {

    ...mapActions('dashboard-module', ['getEstadisticas']),

    async loadData(){

      try {

        this.isLoading = true
        this.data = await this.getEstadisticas() // todas las solicitudes creacion/modificacion

      } catch (errors) {

      } finally {
        this.isLoading = false
      }


    }
  },
  async created() {
    await this.loadData()
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
