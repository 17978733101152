<template>

  <section id="dashboard-ejecutante">

    <b-row class="match-height">
      <b-col
          xl="12"
          md="12"
      >
          <dashboard-ejecutante-estadisiticas/>

      </b-col>

    </b-row>

    <b-row class="match-height">

      <!-- Revenue Report Card -->
      <b-col lg="12">
        <dashboard-ejecutante-anual/>
      </b-col>
      <!--/ Revenue Report Card -->

    </b-row>


    <b-row>

      <b-col md="6">
        <dashboard-ejecutante-completadas/>
      </b-col>

      <!-- Company Table Card -->
      <b-col md="6">
        <dashboard-ejecutante-solicitudes-reciente/>
      </b-col>
      <!--/ Company Table Card -->

    </b-row>

  </section>

</template>

<script>

import { BRow, BCol } from 'bootstrap-vue'

import DashboardEjecutanteEstadisiticas from '@/modules/ejecutante/views/dashboard/DashboardEjecutanteEstadisiticas.vue'
import DashboardEjecutanteCompletadas from "@/modules/ejecutante/views/dashboard/DashboardEjecutanteCompletadas.vue";
import DashboardEjecutanteAnual from "@/modules/ejecutante/views/dashboard/DashboardEjecutanteAnual.vue";
import DashboardEjecutanteSolicitudesReciente from "@/modules/ejecutante/views/dashboard/DashboardEjecutanteSolicitudesReciente.vue";

import DashboardSolicitanteSolicitudes from '@/modules/solicitante/views/dashboard/DashboardSolicitanteSolicitudes.vue'
import DashboardSolicitanteSolicitudesGrafica from '@/modules/solicitante/views/dashboard/DashboardSolicitanteSolicitudesGrafica.vue'
import DashboardSolicitanteEstadisticas from '@/modules/solicitante/views/dashboard/DashboardSolicitanteEstadisticas.vue'
export default {
  name: 'DashboardEjecutante',
  components: {
    DashboardEjecutanteCompletadas,
    DashboardEjecutanteEstadisiticas,
    DashboardSolicitanteSolicitudesGrafica,
    DashboardSolicitanteSolicitudes,
    DashboardEjecutanteSolicitudesReciente,
    BRow,
    BCol,
    DashboardSolicitanteEstadisticas,
    DashboardEjecutanteAnual
  },

  data() {
    return {
      data: {},

      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Products',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Revenue',
          customClass: '',
        },
      ],

    }
  },

}
</script>

<style scoped>

</style>



