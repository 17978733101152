<template>
  <b-card
      no-body
  >
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title> Resumen Solicitudes Ejecutante </b-card-title>

    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>

      <template v-if="isLoading">

        <div class="text-center">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>

      </template>

      <template v-else>
        <b-row>
          <b-col
              sm="2"
              class="d-flex flex-column flex-wrap text-center"
          >
            <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
              {{ this.data['creadas'] }}
            </h1>
            <small>Solicitudes</small>
          </b-col>

          <!-- chart -->
          <b-col
              sm="10"
              class="d-flex justify-content-center"
          >

            <!-- apex chart -->

            <vue-apex-charts
                type="radialBar"
                height="270"
                :options="supportTrackerRadialBar.chartOptions"
                :series="[data.completadas]"
            />


          </b-col>
          <!--/ chart -->
        </b-row>
        <!-- chart info -->
        <div class="d-flex justify-content-between">
          <div class="text-center">
            <b-card-text class="mb-50">
              Pendientes
            </b-card-text>
            <span class="font-large-1 font-weight-bold">{{ this.data['enviadas'] }}</span>
          </div>
          <div class="text-center">
            <b-card-text class="mb-50">
              En Proceso
            </b-card-text>
            <span class="font-large-1 font-weight-bold">{{ this.data['proceso'] }}</span>
          </div>
        </div>
      </template>




    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BSpinner
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { mapActions } from 'vuex'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BSpinner
  },

  data() {
    return {

      isLoading: false,



      data: [

      ],

      supportTracker: {
        totalTicket: 0,
        newTicket: 0,
        openTicket: 0,

      },

      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Finalizadas'],
        },
      },
    }
  },

  methods: {

    ...mapActions('dashboard-module', ['getCompletadas']),

    async loadData(){
      try {
        this.isLoading = true
        this.data = await  this.getCompletadas() // 2023-07-01 | fg | obtener todas las solicitudes creacion/modificacion
      } catch (e) {

      } finally {
        this.isLoading = false
      }

    }
  },
  async created() {
    await this.loadData()
  }

}
</script>
